import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import styled, { css } from "styled-components/macro"
import tw from "twin.macro"
import { Layout } from "../components/Layout"
import Seo from "../components/Seo"

const responsive = {
  xl: {
    breakpoint: { max: 4000, min: 1280 },
    items: 3,
  },
  lg: {
    breakpoint: { max: 1280, min: 1024 },
    items: 2,
  },
  md: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
  },
  sm: {
    breakpoint: { max: 768, min: 640 },
    items: 2,
  },
  xs: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
  },
}

const ProductCard = tw.div`mx-3 mb-4 overflow-hidden`

const ProductCardBody = tw.div`px-6 md:px-12 py-4`

const ProductTitle = styled.div.attrs({
  className: "font-bold text-xl mb-2 font-display uppercase",
})`
  h3 {
    ${tw`h-12 sm:h-12 md:h-16 xl:h-10 text-xs`}
  }
`

const ProductLink = styled(Link)(
  () =>
    tw`block text-center text-xs font-display uppercase bg-blue-600 text-white hover:bg-blue-700 py-4 px-4`
)

const ProductDescription = styled.p.attrs({
  className: "relative overflow-hidden text-gray-700 text-xs h-32 mb-3",
})`
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(
      rgba(221, 221, 221, 0.001) 12rem,
      rgb(221, 221, 221)
    );
  }
`

const ArrowButton = styled.button(({ disabled }) => [
  tw`w-10 h-10 rounded-full text-white pointer-events-auto`,
  disabled
    ? css`
        background: rgba(0, 0, 0, 0.1);
        cursor: not-allowed;
      `
    : css`
        background: rgba(0, 0, 0, 0.3);
      `,
  css`
    & svg {
      ${tw`w-10 fill-current`}
    }
  `,
])

const CustomButtonGroupAsArrows = (props) => {
  const { carouselState, next, previous } = props

  return (
    <div className="absolute inset-0 flex items-center justify-between font-black pointer-events-none">
      <ArrowButton
        onClick={previous}
        disabled={carouselState.currentSlide === 0}
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M14.7 15.3a1 1 0 0 1-1.4 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 0 1 1.4 1.4L11.42 12l3.3 3.3z" />
        </svg>
      </ArrowButton>
      <ArrowButton
        onClick={next}
        disabled={
          carouselState.currentSlide ===
          carouselState.totalItems - carouselState.slidesToShow
        }
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M9.3 8.7a1 1 0 0 1 1.4-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.4-1.4l3.29-3.3-3.3-3.3z" />
        </svg>
      </ArrowButton>
    </div>
  )
}

const ProductsPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Продукция" />

      <div css={tw`relative px-4 sm:px-8`}>
        <Carousel
          responsive={responsive}
          arrows={false}
          renderButtonGroupOutside={true}
          customButtonGroup={<CustomButtonGroupAsArrows />}
        >
          {data.allProducts.edges
            .sort((a, b) => a.node.frontmatter.order - b.node.frontmatter.order)
            .map(({ node }) => {
              const { frontmatter } = node

              return (
                <ProductCard key={node.id}>
                  <Img
                    fluid={frontmatter.thumb.childImageSharp.fluid}
                    alt={frontmatter.title}
                  />
                  <ProductCardBody>
                    <ProductTitle
                      style={{
                        marginBottom: "1.6rem",
                        // frontmatter.title == "ТКР «Волга»"
                        //   ? "1.6rem"
                        //   : "0.8rem",
                      }}
                    >
                      <h2>{frontmatter.title}</h2>
                      <h3>{frontmatter.title2}</h3>
                    </ProductTitle>
                    <ProductDescription>
                      {frontmatter.shortDescription}
                    </ProductDescription>
                    <ProductLink to={`/products/${frontmatter.slug}`}>
                      Подробнее
                    </ProductLink>
                  </ProductCardBody>
                </ProductCard>
              )
            })}
        </Carousel>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allProducts: allMarkdownRemark(
      filter: { frontmatter: { section: { eq: "products" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            title2
            shortDescription
            order
            thumb {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            slug
          }
        }
      }
    }
  }
`

export default ProductsPage
